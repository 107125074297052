import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logoBlack from "../images/logo-black.svg"
import fbIcon from "../images/icons/facebook.svg"
import igIcon from "../images/icons/instagram.svg"

export default function components() {
  return (
    <Wrapper>
      <Container>
        <div className="logo">
          <Link to="/">
            <img src={logoBlack} alt="Logo da Zoombe" />
          </Link>
        </div>
        <div className="address">
          <p>
            Avenida C-255, 370 Sala 1001, <br />
            Swiss Office Tower - Setor Nova Suíça, <br />
            Goiânia - GO
          </p>
        </div>
        <div className="contact">
          <a href="tel:+55 62 62 3622-3632">+55 62 3622-3632</a>
          <br />
          <a href="tel:+55 62 3642-4541">+55 62 3642-4541</a>
        </div>
        <div className="social-media">
          <div className="link">
            <a
              href="https://www.facebook.com/zoombefilmes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={fbIcon} alt="Facebook" />
            </a>
          </div>
          <div className="link">
            <a
              href="https://www.instagram.com/zoombefilmes/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={igIcon} alt="Instagram" />
            </a>
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  background-color: #f5f5f5;
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 140px;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "BrandonGrotesque-Regular", Arial, Helvetica, sans-serif;
  color: black;
  a {
    color: black;
    text-decoration: none;
  }

  div.logo {
    img {
      width: 240px;
    }
  }
  div.address {
  }
  div.contact {
  }
  div.social-media {
    display: flex;
    div.link + div.link {
      margin-left: 10px;
    }
  }

  @media (max-width: 920px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 20px 0px;
    text-align: center;

    div.address {
      padding: 20px 0px;
    }
    div.contact {
      padding: 0px 0px 20px 0px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    padding: 20px 0px;
    text-align: left;
    padding-left: 20px;

    div.address {
      padding: 20px 0px;
    }
    div.contact {
      padding: 0px;
    }

    div.logo {
      order: -1;
    }

    div.social-media {
      padding-top: 20px;
      order: -1;
    }
  }
`
